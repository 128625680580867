import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

/**
 * Combines multiple class values into a single string, merging Tailwind classes efficiently.
 *
 * This function uses clsx to combine class values and tailwind-merge to handle Tailwind-specific class merging.
 *
 * @param {...ClassValue[]} inputs - Any number of class values (strings, objects, or arrays).
 * @returns {string} A merged string of class names.
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

/**
 * Checks if a given string is a valid URL.
 *
 * This function attempts to create a new URL object with the given string.
 * If successful, it's considered a valid URL.
 *
 * @param {string} [url] - The string to check for URL validity.
 * @returns {boolean} True if the string is a valid URL, false otherwise.
 */
export function isValidUrl(url?: string): boolean {
  if (!url) return false
  try {
    new URL(url)
    return true
  } catch (_) {
    return false
  }
}
